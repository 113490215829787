import React, { useState, useEffect } from "react";
import { Box, Card, Typography, Button, useTheme } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import BookSearchBar from "./BookSearchBar";
import CardContentDoublePadding from "./CardContentDoublePadding";

const BookSearchCard = ({ onBookSelect, currentBook, resetSearch = false }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);
  const [showCarousel, setShowCarousel] = useState();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (resetSearch) {
      setSearchResults([]);
      setSelectedBook(null);
    }
  }, [resetSearch]);

  const handleBookSearchResult = (results) => {
    setSearchResults(results);
    setSelectedBook(null);
  };

  const handleBookChoice = (book) => {
    setSelectedBook(book);
  };

  const confirmBookSelection = () => {
    if (selectedBook) {
      onBookSelect(selectedBook);
      setSearchResults([]);
      setSelectedBook(null);
    }
  };

  const triggerLoading = (isLoading) => {
    setLoading(isLoading);
  };

  const defaultCover = "/assets/book-sample.jpg";

  return (
    <Card>
      <CardContentDoublePadding>
        <Typography variant="body1" sx={{ mb: 1, fontWeight: "600" }}>
          {currentBook ? "Switch Book" : "Book Search"}
        </Typography>
        <BookSearchBar
          bookSearchResult={handleBookSearchResult}
          smallLabel
          triggerLoading={triggerLoading}
        />
        {searchResults.length > 0 && !selectedBook && (
          <Box sx={{ mt: 2 }}>
            <Carousel>
              {searchResults.map((book) => (
                <Box
                  key={book.id}
                  sx={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                  }}
                >
                  <img
                    src={
                      book?.cover?.large ||
                      book?.cover?.medium ||
                      book?.cover?.small ||
                      defaultCover
                    }
                    alt={book.title}
                    style={{ height: 100, objectFit: "contain" }}
                  />
                  <Box>
                    <Typography variant="body3" sx={{ fontWeight: "600" }}>
                      {book.title}
                    </Typography>
                    <Typography variant="body2">
                      {book.authors?.join(", ")}
                    </Typography>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        mt: 0.5,
                        color: "white",
                        backgroundColor: theme.palette.neutral.mediumLight,
                        "&:hover": {
                          backgroundColor: theme.palette.neutral.mediumLight,
                        },
                      }}
                      onClick={() => handleBookChoice(book)}
                    >
                      {currentBook ? "Switch to This Book" : "Select This Book"}
                    </Button>
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Box>
        )}
        {selectedBook && (
          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Typography variant="subtitle1">
              {currentBook ? "Switch to:" : "Selected:"} {selectedBook.title}
            </Typography>
            <Button
              onClick={confirmBookSelection}
              variant="contained"
              color="primary"
              sx={{ mt: 1 }}
            >
              {currentBook ? "Confirm Book Switch" : "Confirm Book Selection"}
            </Button>
          </Box>
        )}
      </CardContentDoublePadding>
    </Card>
  );
};

export default BookSearchCard;

// List item instead of Carousel
// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Card,
//   Typography,
//   Button,
//   useTheme,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemAvatar,
//   Avatar,
// } from "@mui/material";
// import BookSearchBar from "./BookSearchBar";
// import CardContentDoublePadding from "./CardContentDoublePadding";

// const BookSearchCard = ({ onBookSelect, currentBook, resetSearch = false }) => {
//   const [searchResults, setSearchResults] = useState([]);
//   const [selectedBook, setSelectedBook] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const theme = useTheme();

//   useEffect(() => {
//     if (resetSearch) {
//       setSearchResults([]);
//       setSelectedBook(null);
//     }
//   }, [resetSearch]);

//   const handleBookSearchResult = (results) => {
//     setSearchResults(results);
//     setSelectedBook(null);
//   };

//   const handleBookChoice = (book) => {
//     setSelectedBook(book);
//   };

//   const confirmBookSelection = () => {
//     if (selectedBook) {
//       onBookSelect(selectedBook);
//       setSearchResults([]);
//       setSelectedBook(null);
//     }
//   };

//   const triggerLoading = (isLoading) => {
//     setLoading(isLoading);
//   };

//   const defaultCover = "/assets/book-sample.jpg";

//   return (
//     <Card>
//       <CardContentDoublePadding>
//         <Typography variant="body1" sx={{ mb: 1, fontWeight: "600" }}>
//           {currentBook ? "Switch Book" : "Book Search"}
//         </Typography>
//         <BookSearchBar
//           bookSearchResult={handleBookSearchResult}
//           smallLabel
//           triggerLoading={triggerLoading}
//         />
//         {searchResults.length > 0 && !selectedBook && (
//           <List sx={{ maxHeight: 300, overflow: "auto", mt: 2 }}>
//             {searchResults.map((book) => (
//               <ListItem
//                 key={book.id}
//                 alignItems="flex-start"
//                 button
//                 onClick={() => handleBookChoice(book)}
//               >
//                 <ListItemAvatar>
//                   <Avatar
//                     variant="rounded"
//                     src={book?.cover?.small || defaultCover}
//                     alt={book.title}
//                   />
//                 </ListItemAvatar>
//                 <ListItemText
//                   primary={book.title}
//                   secondary={book.authors?.join(", ")}
//                 />
//               </ListItem>
//             ))}
//           </List>
//         )}
//         {selectedBook && (
//           <Box sx={{ mt: 2, textAlign: "center" }}>
//             <Typography variant="subtitle1">
//               {currentBook ? "Switch to:" : "Selected:"} {selectedBook.title}
//             </Typography>
//             <Button
//               onClick={confirmBookSelection}
//               variant="contained"
//               color="primary"
//               sx={{ mt: 1 }}
//             >
//               {currentBook ? "Confirm Book Switch" : "Confirm Book Selection"}
//             </Button>
//           </Box>
//         )}
//       </CardContentDoublePadding>
//     </Card>
//   );
// };

// export default BookSearchCard;
