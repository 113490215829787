import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CardMedia,
  Chip,
  Tooltip,
  useTheme,
  Skeleton,
} from "@mui/material";
import CardContentDoublePadding from "../../components/CardContentDoublePadding";

const CurrentBookCard = ({ currentBook, isLoading }) => {
  const theme = useTheme();
  const defaultCover = "/assets/book-sample.jpg";
  const maxGenres = 1;

  if (isLoading) {
    return (
      <Card sx={{ minHeight: 223.96 }}>
        <CardContentDoublePadding>
          <Skeleton variant="text" width="40%" height={24} />
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mt: 1 }}>
            <Skeleton
              variant="rectangular"
              width={96}
              height={144}
              sx={{ borderRadius: "6%" }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
              }}
            >
              <Box>
                <Skeleton variant="text" width="80%" height={20} />
                <Skeleton variant="text" width="60%" height={20} />
              </Box>
              <Skeleton variant="text" width="40%" height={20} />
              <Box>
                <Skeleton variant="text" width="50%" height={20} />
                <Skeleton variant="text" width="30%" height={20} />
              </Box>
            </Box>
          </Box>
        </CardContentDoublePadding>
      </Card>
    );
  }

  if (!currentBook?.title) {
    return (
      <Card>
        <CardContentDoublePadding>
          <Typography variant="body1" sx={{ fontWeight: "600" }}>
            No Book Chosen Yet
          </Typography>
        </CardContentDoublePadding>
      </Card>
    );
  }

  const remainingGenres = currentBook.genre?.length - maxGenres;
  const remainingGenresList = currentBook.genre?.slice(maxGenres).join(", ");

  return (
    <Card sx={{ minHeight: 223.96 }}>
      <CardContentDoublePadding>
        <Typography variant="body1" sx={{ fontWeight: "600", mb: 1 }}>
          Current Book
        </Typography>
        <Box fullWidth sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <Box
            sx={{
              boxShadow: 3,
              width: 84,
              minWidth: 96,
              height: "100%",
              borderRadius: "6%",
            }}
          >
            <CardMedia
              component="img"
              image={
                currentBook.cover?.large ||
                currentBook.cover?.medium ||
                currentBook.cover?.small ||
                defaultCover
              }
              alt="book"
              sx={{ cursor: "pointer", borderRadius: "6%" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box>
              <Typography variant="body3" sx={{ fontWeight: "700" }}>
                {currentBook.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: "600", color: theme.palette.neutral.main }}
              >
                by {currentBook.authors?.join(", ") || "Unknown"}
              </Typography>
            </Box>
            {currentBook.genre && (
              <Box
                fullWidth
                sx={{ display: "flex", gap: 0.5, flexWrap: "wrap" }}
              >
                {currentBook.genre.slice(0, maxGenres).map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    size="small"
                    color="default"
                    sx={{ height: 20, fontSize: "0.7rem" }}
                  />
                ))}
                {remainingGenres > 0 && (
                  <Tooltip title={remainingGenresList}>
                    <Chip
                      label={`+${remainingGenres} more`}
                      size="small"
                      color="default"
                      sx={{ height: 20, fontSize: "0.7rem", cursor: "pointer" }}
                    />
                  </Tooltip>
                )}
              </Box>
            )}
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 0.5 }}>
                <Typography variant="body2">Language:</Typography>
                <Typography variant="body2">
                  {currentBook.language || "Unknown"}
                </Typography>
              </Box>
              <Typography variant="body2">
                Pages: {currentBook.pages || 0}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContentDoublePadding>
    </Card>
  );
};

export default CurrentBookCard;
