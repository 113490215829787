import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Snackbar,
  Button,
  Alert,
} from "@mui/material";
import { UserContext } from "../../context/UserContext";
import { getUsersRooms } from "../../api/roomApi";
import RoomHeader from "./RoomHeader";
import CurrentBookCard from "./CurrentBookCard";
import RoomStatisticsCard from "./RoomStatisticsCard";
import DiscussionSection from "./DiscussionSection";
import MembersCard from "./MembersCard";
import BookSearchCard from "../../components/BookSearchCard";
import RoomDialogs from "./RoomDialogs";
import { useRoomActions } from "../../hooks/useRoomActions";
import { useSubscription } from "../../hooks/useSubscription";
import UpgradeDialog from "../../components/UpgradeDialog";
import ContentGrid from "../../components/ContentGrid";
import LeftProfile from "../../layouts/leftProfile";
import EditBookDataDialog from "../../components/EditBookDataDialog";
import {
  postGoogleBookToDBAndUser,
  addBookToUserLibrary,
} from "../../api/userApi";
import GuildLeftProfile from "./GuildLeftProfile";
const Rooms = () => {
  const theme = useTheme();
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState("");
  const { userProfile } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasRooms, setHasRooms] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [selectedBookId, setSelectedBookId] = useState(null);
  const [isEditBookDialogOpen, setIsEditBookDialogOpen] = useState(false);
  const [loadingRooms, setLoadingRooms] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
  const { getCurrentPlan, getGuildLimit } = useSubscription();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const isMediumScreens = useMediaQuery("(min-width: 1390px)");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const {
    handleCreateRoom,
    handleJoinRoom,
    handleRemoveRoom,
    handleSwitchBook,
    canCreateOrJoinGroup,
    handlePostDiscussion,
    handleEditDiscussion,
    handleRemoveDiscussion,
    handleLikeDiscussion,
    handleRemoveMember,
    dialogStates,
    setDialogStates,
    loading: actionLoading,
  } = useRoomActions(
    rooms,
    setRooms,
    selectedRoom,
    setSelectedRoom,
    userProfile,
    setSnackbar,
    () => setIsUpgradeDialogOpen(true),
    loadingRooms,
    setLoadingRooms
  );

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const data = await getUsersRooms();
        setRooms(data.rooms);
        if (data.rooms.length > 0) {
          setSelectedRoom(data.rooms[0]._id);
          setHasRooms(true);
          setOpenBackdrop(false);
        } else {
          setSelectedRoom("");
          setHasRooms(false);
          setOpenBackdrop(true);
        }
      } catch (err) {
        setError(err.message);
        setSnackbar({
          open: true,
          message: "Failed to fetch rooms: " + err.message,
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchRooms();
  }, []);

  const handleRoomChange = (event) => {
    setSelectedRoom(event.target.value);
  };

  const handleBookSelect = (book) => {
    setSelectedBook(book);
    setIsEditBookDialogOpen(true);
  };

  const handleSaveEditedBook = async (editedBook) => {
    try {
      let result;
      if (!editedBook._id) {
        // Book is from Google Books API and not in our DB yet
        result = await postGoogleBookToDBAndUser(editedBook);
      } else {
        // Book is already in our DB
        result = await addBookToUserLibrary(editedBook._id, {
          title: editedBook.title,
          authors: editedBook.authors,
          pages: editedBook.pages,
          genre: editedBook.genre,
          description: editedBook.description,
        });
      }

      if (result.success) {
        setSelectedBook(result.book);
        setSelectedBookId(result.book._id);
        setIsEditBookDialogOpen(false);

        await handleSwitchBook(result.book);
      } else {
        throw new Error(result.message || "Failed to save book");
      }
    } catch (error) {
      console.error("Error saving edited book:", error);
      // You might want to show an error message to the user here
    }
  };

  const currentRoom = rooms.find((room) => room._id === selectedRoom) || {};
  const isRoomCreator = userProfile._id === currentRoom.creator?._id;

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  console.log("check current room after creation", currentRoom);

  return (
    <>
      {isNonMobileScreens ? (
        <ContentGrid sx={{ gap: 4 }}>
          <GuildLeftProfile
            room={currentRoom}
            isRoomCreator={isRoomCreator}
            onRemoveMember={(member) => {
              setDialogStates({
                ...dialogStates,
                removeMember: true,
                memberToRemove: member,
              });
            }}
          />
          <Box
            sx={{
              width: "100%",
              gridColumn: "span 2",
              mb: isNonMobileScreens ? 2 : 0,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <RoomHeader
                isMobileScreens={!isNonMobileScreens}
                rooms={rooms}
                selectedRoom={selectedRoom}
                onRoomChange={handleRoomChange}
                onCreateRoom={() => {
                  if (canCreateOrJoinGroup()) {
                    setDialogStates({ ...dialogStates, createRoom: true });
                  } else {
                    setIsUpgradeDialogOpen(true);
                  }
                }}
                onJoinRoom={() => {
                  if (canCreateOrJoinGroup()) {
                    setDialogStates({ ...dialogStates, joinRoom: true });
                  } else {
                    setIsUpgradeDialogOpen(true);
                  }
                }}
                isRoomCreator={isRoomCreator}
                canCreateOrJoinGroup={canCreateOrJoinGroup}
                onRemoveRoom={() =>
                  setDialogStates({ ...dialogStates, removeRoom: true })
                }
              />

              {rooms.length > 0 &&
                (isMediumScreens ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      alignItems: "stretch",
                    }}
                  >
                    <Box width="50%">
                      <CurrentBookCard
                        currentBook={currentRoom.currentBook}
                        isLoading={loadingRooms[currentRoom._id]}
                      />{" "}
                    </Box>
                    {currentRoom.currentBook?.title && (
                      <Box width="50%">
                        <RoomStatisticsCard currentRoom={currentRoom} />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <>
                    <CurrentBookCard
                      currentBook={currentRoom.currentBook}
                      isLoading={loadingRooms[currentRoom._id]}
                    />{" "}
                    {currentRoom.currentBook?.title && (
                      <RoomStatisticsCard currentRoom={currentRoom} />
                    )}
                  </>
                ))}

              <DiscussionSection
                discussions={currentRoom.discussions}
                onPostDiscussion={() =>
                  setDialogStates({ ...dialogStates, postDiscussion: true })
                }
                onEditDiscussion={(discussion) => {
                  setDialogStates({
                    ...dialogStates,
                    editDiscussion: true,
                    currentDiscussion: discussion,
                  });
                }}
                onRemoveDiscussion={(discussion) => {
                  setDialogStates({
                    ...dialogStates,
                    removeDiscussion: true,
                    currentDiscussion: discussion,
                  });
                }}
                onLikeDiscussion={handleLikeDiscussion}
                currentUserId={userProfile._id}
              />

              {!isNonMobileScreens && (
                <>
                  {rooms.length > 0 && (
                    <MembersCard
                      members={currentRoom.members}
                      creator={currentRoom.creator}
                      memberProgress={currentRoom.memberProgress}
                      isRoomCreator={isRoomCreator}
                      onRemoveMember={(member) => {
                        setDialogStates({
                          ...dialogStates,
                          removeMember: true,
                          memberToRemove: member,
                        });
                      }}
                    />
                  )}

                  {isRoomCreator && (
                    <BookSearchCard
                      onBookSelect={handleBookSelect}
                      currentBook={currentRoom.currentBook}
                    />
                  )}
                </>
              )}

              <RoomDialogs
                rooms={rooms}
                dialogStates={dialogStates}
                setDialogStates={setDialogStates}
                onCreateRoom={handleCreateRoom}
                onJoinRoom={handleJoinRoom}
                onRemoveRoom={handleRemoveRoom}
                onPostDiscussion={handlePostDiscussion}
                onEditDiscussion={handleEditDiscussion}
                onRemoveDiscussion={handleRemoveDiscussion}
                onRemoveMember={handleRemoveMember}
                currentRoom={currentRoom}
                userProfile={userProfile}
                onOpenUpgradeDialog={() => setIsUpgradeDialogOpen(true)}
                isNonMobileScreens={isNonMobileScreens}
              />
              <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
              >
                <Alert
                  onClose={() => setSnackbar({ ...snackbar, open: false })}
                  severity={snackbar.severity}
                >
                  {snackbar.message}
                </Alert>
              </Snackbar>
              {isRoomCreator && !isNonMobileScreens && (
                <Button
                  fullWidth
                  variant="outlined"
                  color="error"
                  onClick={() =>
                    setDialogStates({ ...dialogStates, removeRoom: true })
                  }
                >
                  <Typography noWrap variant="body2">
                    Delete Guild
                  </Typography>
                </Button>
              )}
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 6, gap: 2 }}>
            {isRoomCreator && (
              <BookSearchCard
                onBookSelect={handleBookSelect}
                currentBook={currentRoom.currentBook}
              />
            )}
            {rooms.length > 0 && (
              <MembersCard
                members={currentRoom.members}
                creator={currentRoom.creator}
                memberProgress={currentRoom.memberProgress}
                isRoomCreator={isRoomCreator}
                onRemoveMember={(member) => {
                  setDialogStates({
                    ...dialogStates,
                    removeMember: true,
                    memberToRemove: member,
                  });
                }}
              />
            )}
          </Box>
        </ContentGrid>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <RoomHeader
              isMobileScreens={!isNonMobileScreens}
              rooms={rooms}
              selectedRoom={selectedRoom}
              onRoomChange={handleRoomChange}
              onCreateRoom={() =>
                setDialogStates({ ...dialogStates, createRoom: true })
              }
              onJoinRoom={() =>
                setDialogStates({ ...dialogStates, joinRoom: true })
              }
              isRoomCreator={isRoomCreator}
              onRemoveRoom={() =>
                setDialogStates({ ...dialogStates, removeRoom: true })
              }
            />

            {rooms.length > 0 && (
              <CurrentBookCard
                currentBook={currentRoom.currentBook}
                isLoading={loadingRooms[currentRoom._id]}
              />
            )}

            {currentRoom.currentBook?.title && (
              <RoomStatisticsCard currentRoom={currentRoom} />
            )}

            <DiscussionSection
              discussions={currentRoom.discussions}
              onPostDiscussion={() =>
                setDialogStates({ ...dialogStates, postDiscussion: true })
              }
              onEditDiscussion={(discussion) => {
                setDialogStates({
                  ...dialogStates,
                  editDiscussion: true,
                  currentDiscussion: discussion,
                });
              }}
              onRemoveDiscussion={(discussion) => {
                setDialogStates({
                  ...dialogStates,
                  removeDiscussion: true,
                  currentDiscussion: discussion,
                });
              }}
              onLikeDiscussion={handleLikeDiscussion}
              currentUserId={userProfile._id}
            />

            {rooms.length > 0 && (
              <MembersCard
                members={currentRoom.members}
                creator={currentRoom.creator}
                memberProgress={currentRoom.memberProgress}
                isRoomCreator={isRoomCreator}
                onRemoveMember={(member) => {
                  setDialogStates({
                    ...dialogStates,
                    removeMember: true,
                    memberToRemove: member,
                  });
                }}
              />
            )}

            {isRoomCreator && (
              <BookSearchCard
                onBookSelect={handleBookSelect}
                currentBook={currentRoom.currentBook}
              />
            )}

            <RoomDialogs
              rooms={rooms}
              dialogStates={dialogStates}
              setDialogStates={setDialogStates}
              onCreateRoom={handleCreateRoom}
              onJoinRoom={handleJoinRoom}
              onRemoveRoom={handleRemoveRoom}
              onPostDiscussion={handlePostDiscussion}
              onEditDiscussion={handleEditDiscussion}
              onRemoveDiscussion={handleRemoveDiscussion}
              onRemoveMember={handleRemoveMember}
              currentRoom={currentRoom}
              userProfile={userProfile}
              onOpenUpgradeDialog={() => setIsUpgradeDialogOpen(true)}
              isNonMobileScreens={isNonMobileScreens}
            />

            <Snackbar
              open={snackbar.open}
              autoHideDuration={6000}
              onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
              <Alert
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                severity={snackbar.severity}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
            {isRoomCreator && !isNonMobileScreens && (
              <Button
                fullWidth
                variant="outlined"
                color="error"
                onClick={() =>
                  setDialogStates({ ...dialogStates, removeRoom: true })
                }
              >
                <Typography noWrap variant="body2">
                  Delete Guild
                </Typography>
              </Button>
            )}
          </Box>
        </Box>
      )}
      <UpgradeDialog
        open={isUpgradeDialogOpen}
        onClose={() => setIsUpgradeDialogOpen(false)}
        title="Upgrade to Join More Guilds"
        currentPlan={getCurrentPlan()}
        limit={getGuildLimit()}
        featureName="guilds"
      />
      <EditBookDataDialog
        open={isEditBookDialogOpen}
        onClose={() => setIsEditBookDialogOpen(false)}
        book={selectedBook || {}}
        onSave={handleSaveEditedBook}
      />
    </>
  );
};

export default Rooms;
