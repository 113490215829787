import { useEffect, useState } from "react";
import {
  Box,
  Card,
  Chip,
  Button,
  Typography,
  useMediaQuery,
  CardMedia,
  useTheme,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import FlexBetween from "../../components/FlexBetween";
import { useNavigate } from "react-router-dom";
import CardContentDoublePadding from "../../components/CardContentDoublePadding";
import DialogNote from "../../components/DialogNote";
import DialogEditNote from "../../components/DialogEditNote";
import DialogAgreeToRemove from "../../components/DialogAgreeToRemove";
import { removeReadingLogById } from "../../api/logApi";
import { getUserReadingLogs } from "../../api/userApi";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

const LogHistoryWidget = ({
  isDiscussion,
  roomTag,
  logId,
  isPicture = true,
  isNotesView = false,
  bookTitle,
  sessionMode,
  bookAuthor = [],
  bookTotalPages,
  logDate,
  lastPageRead,
  startPageRead,
  amountPageRead,
  finishGoalDate,
  privateSwitch,
  notes,
  onUpdateRemoveLog,
  onUpdateNote,
}) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const handleRemoveLog = async () => {
    try {
      await removeReadingLogById(logId);
      onUpdateRemoveLog(logId);
    } catch (error) {
      console.error("Error removing reading log:", error);
    }
  };

  const handleEditNote = async (editedNote, privateSwitch) => {
    try {
      await onUpdateNote(logId, editedNote, privateSwitch);
    } catch (error) {
      console.error("Error updating reading log note:", error);
    }
  };

  const formattedDate = dayjs(finishGoalDate).format("MMMM DD, YYYY");

  return (
    <>
      {isNonMobileScreens ? (
        <Card>
          <CardContentDoublePadding>
            <Box sx={{ display: "flex" }}>
              {isPicture && (
                <Box sx={{ boxShadow: 3, mr: 2, height: "100%" }}>
                  <CardMedia
                    height="120px"
                    component="img"
                    image="/assets/book-sample.jpg"
                    alt="Book Cover"
                  />
                </Box>
              )}

              <FlexBetween sx={{ flexDirection: "column", width: "100%" }}>
                <Box>
                  <FlexBetween sx={{ mb: 0.5 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.neutral.medium,
                        fontWeight: 500,
                      }}
                      gutterBottom
                    >
                      {logDate || "14 Nov 2023"}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      {" "}
                      {isDiscussion && (
                        <Chip
                          size="small"
                          label={`Guild Discussion ${roomTag}`}
                          color="primary"
                        />
                      )}
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.neutral.medium,
                          fontWeight: 500,
                        }}
                      >
                        {privateSwitch ? "Private" : "Public"}
                      </Typography>
                    </Box>
                  </FlexBetween>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 0.5 }}>
                    <Typography
                      variant="body3"
                      sx={{
                        fontWeight: "600",
                        maxHeight: "48px",
                        overflow: "hidden",
                        color: theme.palette.neutral.darkMain,
                        textOverflow: "ellipsis",
                      }}
                    >
                      {bookTitle}
                    </Typography>
                    <Typography
                      variant="body3"
                      sx={{
                        fontWeight: "400",
                        color: theme.palette.neutral.medium,
                      }}
                    >
                      {Array.isArray(bookAuthor) && bookAuthor.length > 1
                        ? ` by ${bookAuthor[0]} ...`
                        : `by ${bookAuthor[0]}` || " "}
                    </Typography>
                  </Box>
                </Box>

                <FlexBetween sx={{ my: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 4,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.neutral.mediumMain,
                          fontWeight: 400,
                        }}
                      >
                        Total Page Read
                      </Typography>
                      <Typography variant="body1" component="div">
                        {amountPageRead < 0 ? 0 : amountPageRead}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.neutral.mediumMain,
                          fontWeight: 400,
                        }}
                      >
                        Start Page
                      </Typography>
                      <Typography variant="body1" component="div">
                        {startPageRead}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.neutral.mediumMain,
                          fontWeight: 400,
                        }}
                      >
                        Last Page
                      </Typography>
                      <Typography variant="body1" component="div">
                        {lastPageRead}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.neutral.mediumMain,
                          fontWeight: 400,
                        }}
                      >
                        Total Book Page
                      </Typography>
                      <Typography variant="body1" component="div">
                        {bookTotalPages}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.neutral.mediumMain,
                          fontWeight: 400,
                        }}
                      >
                        Notes Saved
                      </Typography>
                      {notes ? (
                        <CheckIcon color={theme.palette.neutral.main} />
                      ) : (
                        <ClearIcon color={theme.palette.neutral.main} />
                      )}
                    </Box>
                    {finishGoalDate && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: theme.palette.neutral.mediumMain,
                            fontWeight: 400,
                          }}
                        >
                          FINISH GOAL
                        </Typography>

                        <Typography variant="body1" component="div">
                          {formattedDate}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                    <DialogAgreeToRemove
                      dialogNotes={
                        "Are you sure you want to remove this reading log?"
                      }
                      buttonText={<DeleteIcon />}
                      onRemove={handleRemoveLog}
                    />
                    {!isNotesView && notes && (
                      <>
                        <IconButton
                          size="small"
                          onClick={() => setIsEditDialogOpen(true)}
                          color="neutral"
                        >
                          <EditIcon />
                        </IconButton>
                        <DialogNote
                          bookTitle={bookTitle}
                          buttonText={<VisibilityIcon />}
                          dialogTitle={logDate}
                          readingLogNote={notes}
                        />
                      </>
                    )}
                    <DialogEditNote
                      open={isEditDialogOpen}
                      onClose={() => setIsEditDialogOpen(false)}
                      onSave={handleEditNote}
                      switchData={privateSwitch}
                      initialNote={notes}
                      bookTitle={bookTitle}
                      logDate={logDate}
                      logId={logId}
                    />
                  </Box>
                </FlexBetween>
              </FlexBetween>
            </Box>
          </CardContentDoublePadding>
        </Card>
      ) : (
        <Card sx={{ width: "100%" }}>
          <CardContentDoublePadding>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <FlexBetween sx={{ mb: 0.5 }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "11px",
                    color: theme.palette.neutral.medium,
                    fontWeight: 500,
                  }}
                  gutterBottom
                >
                  {logDate || "14 Nov 2023"}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {isDiscussion && (
                    <Chip
                      size="small"
                      label={`Guild Discussion ${roomTag}`}
                      color="primary"
                    />
                  )}
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "11px",
                      color: theme.palette.neutral.medium,
                      fontWeight: 500,
                    }}
                    gutterBottom
                  >
                    {privateSwitch ? "Private" : "Public"}
                  </Typography>
                </Box>
              </FlexBetween>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 0.5 }}>
                <Typography
                  variant="body3"
                  sx={{
                    fontWeight: "600",
                    maxHeight: "48px",
                    overflow: "hidden",
                    color: theme.palette.neutral.darkMain,
                    textOverflow: "ellipsis",
                  }}
                >
                  {bookTitle}
                </Typography>
                <Typography
                  variant="body3"
                  sx={{
                    fontWeight: "400",
                    color: theme.palette.neutral.medium,
                  }}
                >
                  {Array.isArray(bookAuthor) && bookAuthor.length > 1
                    ? ` by ${bookAuthor[0]} ...`
                    : `by ${bookAuthor[0]}` || " "}
                </Typography>
              </Box>
            </Box>

            <FlexBetween sx={{ my: 0.5 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "11px",
                      color: theme.palette.neutral.mediumMain,
                      fontWeight: 400,
                    }}
                  >
                    Log Page:
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{
                      color: theme.palette.neutral.main,
                    }}
                  >
                    {lastPageRead}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 0.5,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.neutral.mediumMain,
                      fontWeight: 400,
                    }}
                  >
                    Notes Saved:
                  </Typography>
                  {notes ? (
                    <CheckIcon
                      sx={{
                        fontSize: "18px",
                        color: theme.palette.neutral.main,
                      }}
                    />
                  ) : (
                    <ClearIcon
                      sx={{
                        fontSize: "18px",
                        color: theme.palette.neutral.main,
                      }}
                    />
                  )}
                </Box>
                {finishGoalDate && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 0.5,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.neutral.mediumMain,
                        fontWeight: 400,
                      }}
                    >
                      FINISH GOAL
                    </Typography>

                    <Typography variant="body1" component="div">
                      {formattedDate}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                <DialogAgreeToRemove
                  dialogNotes={
                    "Are you sure you want to remove this reading log?"
                  }
                  buttonText={<DeleteIcon />}
                  onRemove={handleRemoveLog}
                />
                {!isNotesView && notes && (
                  <>
                    <IconButton
                      size="small"
                      onClick={() => setIsEditDialogOpen(true)}
                      color="default"
                    >
                      <EditIcon />
                    </IconButton>
                    <DialogNote
                      bookTitle={bookTitle}
                      buttonText={<VisibilityIcon />}
                      dialogTitle={logDate}
                      readingLogNote={notes}
                    />
                  </>
                )}
                <DialogEditNote
                  open={isEditDialogOpen}
                  onClose={() => setIsEditDialogOpen(false)}
                  onSave={handleEditNote}
                  initialNote={notes}
                  bookTitle={bookTitle}
                  logDate={logDate}
                  logId={logId}
                />
              </Box>
            </FlexBetween>

            {/* button option take picture, share to friends, or remove log
        if log is removed, should the starting page back ot the last ending page?*/}
          </CardContentDoublePadding>
        </Card>
      )}
    </>
  );
};

export default LogHistoryWidget;
