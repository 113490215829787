import React from "react";
import {
  Card,
  CardContent,
  Box,
  Avatar,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CardContentDoublePadding from "../../../components/CardContentDoublePadding";

const RoundedSquareAvatar = styled(Avatar)(({ theme }) => ({
  width: 64,
  height: 64,
  borderRadius: theme.shape.borderRadius * 1,
}));

const ExtraSlimAccordion = styled(Accordion)(({ theme }) => ({
  "&.MuiAccordion-root": {
    margin: 0,
    "&:before": {
      display: "none",
    },
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));

const ExtraSlimAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: "unset",
  "&.MuiAccordionSummary-root": {
    padding: theme.spacing(0, 1),
  },
  "& .MuiAccordionSummary-content": {
    margin: theme.spacing(0.5, 0),
  },
}));

const ExtraSlimAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const GuildAccordionHeading = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
}));

const GuildProfile = ({ room }) => {
  return (
    <Card>
      <CardContentDoublePadding>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <RoundedSquareAvatar alt={room.name} src={room.profilePicture} />
          <Typography variant="h6" sx={{ ml: 2, fontWeight: "bold" }}>
            {room.name}
          </Typography>
        </Box>
        {room?.description && (
          <ExtraSlimAccordion>
            <ExtraSlimAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="description-content"
              id="description-header"
            >
              <GuildAccordionHeading variant="body3">
                Description
              </GuildAccordionHeading>
            </ExtraSlimAccordionSummary>
            <ExtraSlimAccordionDetails>
              <Typography variant="body2" color="text.secondary">
                {room.description}
              </Typography>
            </ExtraSlimAccordionDetails>
          </ExtraSlimAccordion>
        )}
        {room?.about && (
          <ExtraSlimAccordion>
            <ExtraSlimAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="about-content"
              id="about-header"
            >
              <GuildAccordionHeading variant="body3">
                About
              </GuildAccordionHeading>
            </ExtraSlimAccordionSummary>
            <ExtraSlimAccordionDetails>
              <Typography variant="body2" color="text.secondary">
                {room.about}
              </Typography>
            </ExtraSlimAccordionDetails>
          </ExtraSlimAccordion>
        )}
      </CardContentDoublePadding>
    </Card>
  );
};

export default GuildProfile;
